import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input_number = _resolveComponent("el-input-number")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_radio = _resolveComponent("el-radio")
  const _component_el_form = _resolveComponent("el-form")
  const _component_el_button = _resolveComponent("el-button")
  const _component_dialog_shell = _resolveComponent("dialog-shell")

  return (_openBlock(), _createBlock(_component_dialog_shell, {
    footerType: "customize",
    width: "600px",
    title: _ctx.$wordList.TabelConfigInHtmlModify,
    onClose: _ctx.close,
    onSubmit: _ctx.submit
  }, {
    footer: _withCtx(() => [
      _createVNode("span", _hoisted_1, [
        _createVNode(_component_el_button, {
          type: "danger",
          style: {"float":"left"},
          onClick: _ctx.update
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$wordList.TabelFootUpdateBillingModel), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_el_button, {
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('close')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$wordList.TabelFootCancel), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "primary",
          onClick: _ctx.submit
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$wordList.TabelFootSubmit), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_form, null, {
        default: _withCtx(() => [
          (_ctx.type === 'community')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 0,
                label: _ctx.$wordList.ProperAllTextNumberOfApt
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input_number, {
                    modelValue: _ctx.formData.NumberOfApt,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.formData.NumberOfApt = $event)),
                    min: 0,
                    max: _ctx.maxAptNumber
                  }, null, 8, ["modelValue", "max"])
                ]),
                _: 1
              }, 8, ["label"]))
            : _createCommentVNode("", true),
          _createVNode(_component_el_form_item, {
            label: _ctx.$wordList.ProperAllTextLandlineService
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_radio, {
                modelValue: _ctx.formData.EnableLandline,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.formData.EnableLandline = $event)),
                label: 1
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$wordList.ProperAllTextOn), 1)
                ]),
                _: 1
              }, 8, ["modelValue"]),
              _createVNode(_component_el_radio, {
                modelValue: _ctx.formData.EnableLandline,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.formData.EnableLandline = $event)),
                label: 0
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$wordList.ProperAllTextOff), 1)
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["title", "onClose", "onSubmit"]))
}