
import {
    computed,
    defineComponent,
    PropType,
    watchEffect,
    reactive
} from 'vue';
import dialogShell from '@/components/common/dialog-shell/index.vue';
import { ProjectType } from '@/views/super/community/util';
import { projectForSuperApi } from '@/api';

interface SetCommunityForm {
    NumberOfApt: number | string;
    EnableLandline: number | string;
}

export default defineComponent({
    components: {
        dialogShell
    },
    emits: ['close', 'success', 'update'],
    props: {
        initForm: {
            type: Object as PropType<SetCommunityForm>,
            required: true
        },
        type: {
            type: String as PropType< ProjectType >,
            default: 'community'
        }
    },
    setup(props, { emit }) {
        const maxAptNumber = 65535;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const formData = computed(() => reactive({ ...props.initForm }));
        watchEffect(() => {
            formData.value.NumberOfApt = Number(formData.value.NumberOfApt);
            formData.value.EnableLandline = Number(formData.value.EnableLandline);
        });
        const close = () => {
            emit('close');
        };

        const submit = () => {
            const api = props.type === 'office' ? projectForSuperApi.editOfficeChargePlan : projectForSuperApi.editCommunityChargePlan;
            api(formData.value, () => {
                emit('success');
                close();
            });
        };

        const update = () => {
            emit('update');
        };

        return {
            maxAptNumber,
            formData,
            submit,
            close,
            update
        };
    }
});
